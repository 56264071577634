<template>
    <div class="documentNotesManage" id="ygh-content-box">
        <a-card :bordered="false" class="height100">
            <s-table ref="table" :columns="columns" :data="loadData" :alert="false" rowKey="id">
                <span slot="operator">
                    <div class="flex flex-jc-sb flex-ai-c" style="margin: 0">
                        <div>
                            <a-input-search
                                placeholder="请输入关键字"
                                @search="onSearch"
                                class="search"
                                v-model="queryParam.name"
                            >
                                <a-button slot="enterButton" icon="search"> </a-button>
                            </a-input-search>
                        </div>
                        <div>
                            <a-button
                                style="background: none; color: #40a9ff"
                                type="primary"
                                shape="round"
                                size="small"
                                icon="plus"
                                @click="$refs.addNote.open()"
                                >新增名称</a-button
                            >
                        </div>
                    </div>
                </span>
                <template slot="createTime" slot-scope="text">
                    {{ formatDate(text) }}
                </template>
                <template slot="updateTime" slot-scope="text">
                    {{ formatDate(text) }}
                </template>
                <template slot="createBy" slot-scope="text, record">
                    <OpenData v-if="record.corpId" type="userName" :openid="text" :corpid="record.corpId"></OpenData>
                    <template v-else>系统内置</template>
                </template>
                <template slot="typeList" slot-scope="text, record">
                    <template v-if="record.typeList && record.typeList.length">
                        <a-tag color="blue" v-for="i in record.typeList" :key="i">
                            {{ getTagName(i) }}
                        </a-tag>
                    </template>
                </template>
                <span slot="action" slot-scope="text, record">
                    <template v-if="record.corpId">
                        <a-button @click="$refs.addNote.open(record)" style="padding-left: 0" type="link"
                            >编辑</a-button
                        >
                        <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => singleDelete(record)">
                            <a-button style="color: red; padding-left: 0" type="link">删除</a-button>
                        </a-popconfirm>
                    </template>
                    <template v-else>--</template>
                </span>
            </s-table>
        </a-card>
        <addNote @ok="$refs.table.refresh()" ref="addNote" />
    </div>
</template>
<script>
import { documentTabDelete, documentTabPage } from '@/api/modular/fileStream/processingSignature'
import { STable } from '@/components'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import moment from 'moment'
import addNote from './components/addNote.vue'
const columns = [
    {
        title: '处理笺名称',
        dataIndex: 'name',
        ellipsis: true,
    },
    {
        title: '使用范围',
        dataIndex: 'typeList',
        scopedSlots: { customRender: 'typeList' },
    },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
    },
    {
        title: '更新时间',
        dataIndex: 'updateTime',
        scopedSlots: { customRender: 'updateTime' },
    },
    {
        title: '创建人',
        dataIndex: 'createBy',
        scopedSlots: { customRender: 'createBy' },
    },
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
export default {
    components: {
        OpenData,
        STable,
        addNote,
    },
    data() {
        return {
            columns,
            queryParam: {},
            loadData: (parameter) => {
                return documentTabPage({ ...parameter, ...this.queryParam })
                    .then((res) => res.data)
                    .catch(() => {
                        return new Promise((resolve, reject) => {
                            resolve({
                                pageNo: 1,
                                pageSize: 10,
                                totalPage: 0,
                                totalRows: 0,
                                rows: [],
                                rainbow: [],
                            })
                        })
                    })
            },
        }
    },
    created() {
        init_wx()
    },
    methods: {
        singleDelete({ id }) {
            documentTabDelete([id]).then((res) => {
                if (res.code === 200) {
                    this.$message.success('删除成功')
                    this.$refs.table.refresh()
                } else {
                    this.$message.error(res.message)
                }
            })
        },
        onSearch(val) {
            this.$refs.table.refresh(true)
        },
        formatDate(text) {
            if (!text) return ''
            return moment(text).format('YYYY-MM-DD hh:mm:ss')
        },
        getTagName(text) {
            switch (text) {
                case 1:
                    return '收文登记'
                case 2:
                    return '快速发文'
                case 3:
                    return '拟稿发文'
            }
        },
    },
}
</script>
<style lang="less" scoped>
#ygh-content-box {
    height: calc(100% - 17px - 24px);
    margin: 0 auto !important;
    background: #fff;
}
</style>